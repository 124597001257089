import type { NextPage } from "next";
import { Page as PageComponent } from "@components/layouts/components/Page/Page";

export { getServerSideProps } from "@utils/pages";

const Page: NextPage = ({ page, allPages }: any) => {
  return page ? <PageComponent page={page} allPages={allPages} /> : null;
};

export default Page;
